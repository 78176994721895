<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="姓名">
          <a-input v-model="query.userName" placeholder="姓名"></a-input>
        </query-item>
        <query-item label="手机号">
          <a-input v-model="query.mobile" placeholder="手机号"></a-input>
        </query-item>
        <query-item label="创建时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { sysStoreSubUserList, systemUserStatus } from '@/api/user';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '@/components/IDatePicker.vue';

export default {
  name: 'RoleList',
  mixins: [queryMixin],
  components: {
    IDatePicker
  },
  props: {},
  data() {
    return {
      query: {
        shopId: null,
        loginName: null,
        mobile: null,
        userName: null,
        duration: [],
      },
      currentUser: null,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '账号',
          dataIndex: 'loginName',
          key: 'loginName',
          ellipsis: true,
          width: 150,
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 150,
        },
        // {
        //   title: '角色名称',
        //   dataIndex: 'roleName',
        //   key: 'roleName',
        //   ellipsis: true,
        //   width: 120,
        // },
        {
          title: '性别',
          dataIndex: 'sexName',
          key: 'sexName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          // width: 160,
        },
        // {
        //   title: '状态',
        //   dataIndex: 'status',
        //   key: 'status',
        //   width: 120,
        //   customRender: (text, row) => {
        //     return (
        //       <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
        //     );
        //   }
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 220,
        //   scopedSlots: { customRender: 'action' },
        //   customRender: (text, row) => {
        //     return (
        //       <section>
        //         <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
        //       </section>
        //     );
        //   }
        // }
      ],
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];
      sysStoreSubUserList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'storeUserAdd'
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'storeUserEdit',
        params: {
          id: row.userId
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleStatus(row) {
      const params = {
        userId: row.userId,
        status: row.status === 0 ? 1 : 0
      };

      systemUserStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
